import unescape from 'lodash/unescape';
import fetchWithTimeout from './fetchWithTimeout';

const API = {};

API.call = async (url, options = {}) => {
  const fullUrl = process.env.GATSBY_API_URL + '/' + url;

  // if (typeof options.schema !== 'undefined') {
  //   delete options.schema;
  // }

  // console.log('options', options);

  options.redirect = 'follow';

  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  };

  let response;
  response = await fetchWithTimeout(fullUrl, options);
  if (response.ok && response.status === 200) {
    const json = await response.json();
    return {json, response};
  } else {
    const error = unescape(await response.text());
    throw new Error(error);
  }
};

API.delete = (url, options = {}) => {
  options.method = 'DELETE';

  return API.call(url, options);
};

API.get = (url, options = {}) => {
  options.method = 'GET';

  return API.call(url, options);
};

API.post = (url, options = {}) => {
  options.method = 'POST';

  return API.call(url, options);
};

export default API;
